<template>
  <div class="mx-24 mt-20 mb-40">
    <h1>Order Intake</h1>
    <p v-if="errorFindingHomeLottery">Error loading event, please contact support</p>
    <BaseButton variant="success" @click.native="handleRedirectToCheckout">Go to Inbound Checkout</BaseButton>
  </div>
</template>

<script>
import EventServiceV2 from '@/lib/event-service-v2';
import config from '@/config';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  name: 'OrderIntake',
  components: {
    BaseButton
  },
  data() {
    return {
      checkoutUrl: config.CHECKOUT_URL,
      events: [],
      homeLotteryEventId: null,
      loading: false,
      errorFindingHomeLottery: false
    };
  },
  created() {
    this.loadEvents();
  },
  methods: {
    async loadEvents() {
      try {
        this.loading = true;
        const result = await EventServiceV2.listEvents();
        const events = result.data;

        const event = events.find((event) => event.isHomeLottery == true && event.status === 'active');
        const eventId = event ? event.id : null;

        if (!eventId) {
          this.errorFindingHomeLottery = true;
        } else {
          this.homeLotteryEventId = eventId;
        }

        this.loading = false;
      } catch (error) {
        this.handleError(error);
        this.events = [];
      }
    },
    inboundCheckoutUrl() {
      const user = this.$store.state.user;
      return `${this.checkoutUrl}/single?eventId=${this.homeLotteryEventId}&locale=en&createOrder=true&userId=${user.id}`;
    },
    handleRedirectToCheckout() {
      window.open(this.inboundCheckoutUrl(), '_blank');
    }
  }
};
</script>
